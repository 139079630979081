import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import { mapGetters } from 'vuex'
import Page from '@/calendesk/models/DTO/Response/Page'

export default mixins(Section).extend({
  data () {
    return {
      isUserLogged: false,
      date: new Date().getFullYear()
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      currentPage: 'builder/getCurrentPage'
    }),
    socialMedia (): Record<string, any> {
      return [
        { icon: '$fb', link: this.data.configuration.wb_facebook__url__ },
        { icon: '$instagram', link: this.data.configuration.wb_instagram__url__ },
        { icon: '$yt', link: this.data.configuration.wb_youtube__url__ },
        { icon: '$twitter', link: this.data.configuration.wb_twitter__url__ }
      ]
    },
    authorizationButtons (): Array<Record<string, any>> {
      return [
        {
          title: this.$trans('wb_login'),
          icon: '$account',
          key: 'top-login',
          color: 'wb_color_text__color__',
          borderOpacity: '0.16'
        },
        {
          title: this.$trans('wb_sign_up'),
          icon: '$addUser',
          key: 'top-signup',
          color: 'wb_color_primary__color__',
          borderOpacity: '1'
        }
      ]
    },
    getLogoSRC (): string | null {
      return this.getImageUrl('logo')
    },
    pageList (): Page[] {
      const pageList: Page[] = new Array<Page>()
      if (this.data && this.data.configuration && Array.isArray(this.data.configuration.wb_page_list__page_ids__)) {
        const uuids: Array<string> = this.data.configuration.wb_page_list__page_ids__
        uuids.forEach((uuid: string) => {
          const page: Page | undefined = this.draftPages.find((_: Page) => _.uuid === uuid)
          if (page) {
            pageList.push(page)
          }
        })
      }

      return pageList
    },
    mainPages (): Page[] {
      return this.pageList.slice(0, this.data.configuration.wb_main_pages_number__number__)
    },
    additionalPages (): Page[] {
      if (this.pageList.length > this.data.configuration.wb_main_pages_number__number__) {
        return this.pageList.slice(this.data.configuration.wb_main_pages_number__number__ - 1, -1)
      }
      return []
    }
  },
  watch: {

  },
  methods: {

  }
})
