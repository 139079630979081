





















import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import Page from '@/calendesk/models/DTO/Response/Page'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'
export default mixins(DraftElement).extend({
  props: {
    title: {
      type: String,
      default: 'More'
    },
    color: {
      type: String,
      default: 'wb_color_text__color__'
    },
    navbarAdditionalPages: {
      type: Array,
      default: () => Array<Page>()
    },
    buttonSize: {
      type: Number,
      default: ButtonSizeType.MEDIUM
    }
  },
  name: 'AdditionalPagesMenu'
})
